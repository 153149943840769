<script setup lang="ts">
const props = withDefaults(defineProps<{
  title?: string
  subTitle?: string
  icon?: string
  size?: 'md' | 'lg'
  classes?: {
    // The css classes for the icon container
    iconContainer?: string
  }
}>(), {
  title: undefined,
  subTitle: undefined,
  icon: undefined,
  size: 'md',
  classes: undefined,
})

const sizeClasses = props.size == 'md' ? 'sm:mx-auto sm:max-w-xl' : ''

</script>

<template>
  <BaseCard
    shape="curved"
    class="relative mx-auto overflow-hidden px-4 py-10 sm:px-10"
    :class="sizeClasses"
  >
    
    
    <div v-if="$slots.topnav" class="inset-x-0 top-0 -mx-4 -mt-10 sm:-mx-10">
      <slot name="topnav" />
    </div>

    
    <div v-if="title || subTitle || icon" class="mb-8 flex items-center gap-4">
      <div
        :class="classes?.iconContainer || 'bg-primary-500/20 text-primary-500 flex size-14 shrink-0 items-center justify-center rounded-full font-sans text-2xl first-letter:flex'"
      >
        <slot name="icon">
          <Icon
            v-if="icon"
            :name="icon"
            class="size-5"
          />
        </slot>
      </div>
      <div
        class="dark:text-primary-300 text-muted-700 block text-xl font-semibold"
      >
        <BaseHeading
          as="h3"
          size="lg"
          weight="medium"
        >
          <slot name="title">
            {{ title }}
          </slot>
        </BaseHeading>
        <BaseText size="sm" class="text-muted-400">
          <slot name="subTitle">
            {{ subTitle }}
          </slot>
        </BaseText>
      </div>
    </div>

    
    <slot />
  </BaseCard>
</template>
